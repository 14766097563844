import { mapState } from 'vuex'

export default {
  name: 'TFilesTable',
  data () {
    return {
      activeStatus: true
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    thead: {
      type: Array,
      default: () => []
    },
    fileStatus: {
      type: Array,
      default: () => []
    },
    files: {
      type: Array,
      default: () => []
    },
    extraOptions: {
      type: Boolean,
      default: false
    },
    extraOptionsItems: {
      type: Array,
      default: () => []
    },
    accordionTitleClass: {
      type: String,
      default: ''
    },
    showTotal: {
      type: Boolean,
      default: false
    },
    total: {
      type: Number,
      default: 0
    },
    pagination: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      currentMonty: ({ files }) => files.currentMonty,
      currentYear: ({ files }) => files.currentYear
    }),
    filesList () {
      this.bindPlugin()
      return this.files
    }
  },
  methods: {
    returnDestination (extraOption, items) {
      let params = '#'
      const { commit } = this.$store
      if (extraOption.link !== '#') {
        commit('files/SET_CURRENT_MONTH', this.currentMonty)
        commit('files/SET_CURRENT_YEAR', this.currentYear)

        params = {name: extraOption.link, params: this.returnIdItem(items)}
      }
      return params
    },
    returnIdItem (itemList) {
      let idItem = {}
      if ('id' in itemList) {
        idItem = { id: itemList.id }
      }
      return idItem
    },
    getHeadClass (headName, tableName) {
      if (headName === 'billings' || (headName === 'registers' && tableName !== 'importedReturns')) {
        return 'black-300'
      }
      if (headName === 'paymentMethod') {
        return 'column-100'
      }
    },
    solveColumnItem (item, key) {
      if (key === 'id' || key === 'bankCode') {
        return 'hidden'
      }

      if (!isNaN(item) && key !== 'id') {
        return 'center aligned'
      }
    },
    bindPlugin () {
      Vue.nextTick().then(() => {
        $(`.extra-options .tui.ui.dropdown`).dropdown()
      })
    }
  }
}